.inputWithSelectKitContainer {
  background-color: transparent;
  border-radius: 12px;
  padding: 8px 12px 8px 8px;
  position: relative;
  max-height: 48px;
  border: 0.5px solid var(--text-caption);
  &.background {
    background-color: var(--surface);
  }
  &.required {
    .mat-mdc-floating-label mat-label {
      &::after {
        content: '*';
        display: inline-block;
        margin-left: 4px;
        vertical-align: top;
        color: var(--text-primary);
      }
    }
  }
  &.invalidInput {
    border: 1px solid var(--red-primary);
    .mat-mdc-floating-label mat-label {
      &::after {
        color: var(--red-primary);
      }
    }
  }
  &.focused {
    border: 1px solid var(--primary);
  }
  &.disabledInput {
    border-color: var(--divider);
  }
  .inputHint {
    padding: 0 14px;
    font-weight: 400;
    font-size: 11px;
    line-height: 1.4;
    color: var(--text-caption);
    &.errorHint {
      color: var(--red-primary);
      position: absolute;
      bottom: -17px;
      left: 0;
    }
  }
  .selectFormField {
    width: 56px;
    min-width: 56px;
    .mat-mdc-text-field-wrapper {
      overflow: visible;
    }
    .mat-mdc-form-field-infix {
      border-radius: 8px;
      background-color: var(--overlay-dark);
      padding: 4px 0 4px 4px;
    }
    .mat-mdc-select-arrow {
      margin: 0;
    }
  }
  .inputFormField {
    .mat-mdc-text-field-wrapper.mdc-text-field {
      overflow: visible;
    }
    .mat-mdc-form-field-infix {
      padding-top: 12px;
      min-height: auto;
    }
    .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
      top: 18px;
    }
  }
  .tooltipContainer {
    margin-top: -8px;
  }
}
